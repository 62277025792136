import * as React from "react"
import Homepage from "../../components/homepage/homepage"
import Layout from "../../components/layout"
import SeoNoIndex from "../../components/seo-no-index-mida"

const IndexPage = () => (
  <Layout nav={'hidden'}>
    <SeoNoIndex title="Homepage" index={false} follow={false}/>
    <Homepage />
  </Layout>
)

export default IndexPage
